<template>
<div>
  <van-field v-bind="$attrs" readonly style="display: none;"/>
  <van-field
      v-model="pickerText"
      :label="$attrs.label"
      is-link
      readonly
      :placeholder="props.placeholder"
      @click="showPicker = true"
  />
  <van-popup :show="showPicker && !isEmpty(props.columns)" round position="bottom">
    <van-picker
        :columns="props.columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
    />
  </van-popup>
</div>
</template>

<script>
import {Picker, Field, Popup} from "vant";

export default {
  name: "FieldPicker",
  components: {
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
  },
}
</script>
<script setup>
import {ref, defineEmits, defineProps, useAttrs, watch} from "vue";
import {isEmpty} from 'lodash-es';
const emits = defineEmits(["update:modelValue", 'dochange'])
const props = defineProps({
  placeholder:{
    type:String,
    default:'点击选择'
  },
  columns:{
    type:[Array, Object]
  },
})
const attr = useAttrs()
const selectValueDeal = () => {
  const foundItem = attr?.modelValue ? (props.columns).find(item => item.value == attr?.modelValue) : ''
  return foundItem ? foundItem.text : ''
}
const showPicker = ref(false)
const is_select = ref(false)
const pickerText = ref(selectValueDeal())

watch(() => props.columns, (newValue, oldVal) => {
  if (!(is_select.value) && (newValue || newValue == 0)) {
    pickerText.value = selectValueDeal()
  }
}, { immediate: true, deep: true })


const onConfirm = (option) => {
  is_select.value = true
  pickerText.value = option.text
  emits('update:modelValue',option.value)
  emits('dochange',option)
  showPicker.value = false
}
</script>

<style scoped>

</style>
