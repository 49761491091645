<template>
  <div class="main gray-bg has-bottom">
    <van-nav-bar class="top-bar" title="申请返利" left-arrow @click-left="onClickLeft"  right-text="申请记录" @click-right="$router.push('/personal/activity-rebate-list')"/>
    <van-form class="label-form" v-model:loading="loading">
      <van-cell-group>
        <van-field label="游戏名称：" :model-value="options.game_name" readonly />
        <field-picker class="custom-cell" label="选择小号：" v-model="query.small_id" :columns="smallColumns" @dochange="doChangeSmallId"></field-picker>
        <van-field label="游戏区服：" v-model="query.server_name" clearable/>
        <van-field label="角色ID：" v-model="query.game_player_id" clearable/>
        <van-field label="角色名称：" v-model="query.game_player_name" clearable/>
        <time-range-select class="custom-cell" label="充值时间：" ref="timeSelectRef" :max-range="60" v-model:startTime="query.start_time" v-model:endTime="query.end_time" :default-date="createTimeSelect" @dochange="handleGetRechargeAmount"></time-range-select>
        <van-field label="充值金额：" :model-value="options.recharge_amount" readonly />
        <van-field label="申请内容:" label-align="top" v-model="query.content" rows="2" autosize type="textarea" placeholder="请填写所需申请活动内容及道具"/>
      </van-cell-group>
    </van-form>
    <!--底部按钮-->
    <div class="bottom-bar">
      <van-button type="primary" round @click="onSubmit" :loading="isLoading"
                  loading-text="提交中..." block>申请返利</van-button>
    </div>
  </div>
</template>

<script>
import {Button, CellGroup, Field, Form, NavBar} from "vant";

export default {
  name: "ActivityRebateOperate",
  components: {
    [CellGroup.name]: CellGroup,
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [Form.name]: Form,
    [Field.name]: Field,
  },
}
</script>
<script setup>
import {onClickLeft} from "@/utils/function";
import {onMounted, reactive, ref} from "vue";
import FieldPicker from "@/components/FieldPicker";
import {smallList} from "@/api/user";
import TimeRangeSelect from "@/components/TimeRangeSelect";
import {getLastRoleInfo, getRechargeAmount, rebateAdd, rebateDetails} from "@/api/user_active_rebate";
import {useRoute, useRouter} from "vue-router";
import {Toast} from "vant";

const {params} = useRoute();
const router = useRouter();
const loading = ref(true);
const isLoading = ref(false)
const smallColumns = ref([])
const timeSelectRef = ref('')
const createTimeSelect = ref([])
const options = reactive({
  game_name:params.game_name,
  recharge_amount:0,
})
const query = reactive({
  active_id:params.activity_id,
  small_id:0,
  server_name:'',
  game_player_id:'',
  game_player_name:'',
  start_time:'',
  end_time:'',
  content:''
})

onMounted(async ()=>{
  if(params?.ids){
    await rebateDetails(params.ids).then((res)=>{
      options.game_name = res.data.game_name
      options.recharge_amount = res.data.recharge_amount
      query.active_id = res.data.active_id
      query.small_id = res.data.small_id
      query.server_name = res.data.server_name
      query.game_player_id = res.data.game_player_id
      query.game_player_name = res.data.game_player_name
      query.start_time = res.data.start_time
      query.end_time = res.data.end_time
      query.content = res.data.content
      if(query.start_time){
        createTimeSelect.value.push(query.start_time)
      }
      if(query.end_time){
        createTimeSelect.value.push(query.end_time)
      }
    })
  }
  smallList().then((res) => {
    let opt = []
    for (let i = 0; i < res.data.length; i++) {
      opt.push({text:res.data[i].nickname,value:res.data[i].id})
    }
    smallColumns.value = opt
  }).finally(()=>{
    loading.value = false
  });
})
const doChangeSmallId = (option) => {
  getLastRoleInfo(option.value).then((res)=>{
    if(res?.data && res.data.length < 0){
      Toast("当前小号暂无角色信息，请手动输入");
    }
    query.server_name = res.data.server_name
    query.game_player_id = res.data.game_player_id
    query.game_player_name = res.data.game_player_name
    timeSelectRef.value.handleReset()
    options.recharge_amount = 0
  })
}

const handleGetRechargeAmount = () => {
  if(query.small_id && query.start_time && query.end_time){
    getRechargeAmount({
      small_id:query.small_id,
      start_time:query.start_time,
      end_time:query.end_time,
    }).then((res)=>{
      options.recharge_amount = res.data.recharge_amount
    })
  }
}
const onSubmit = () => {
  rebateAdd(query).then((res) =>{
    Toast(res.message);
    router.push('/personal/activity-rebate-list')
  })
}
</script>

<style scoped>
:deep(.van-nav-bar__text){
  font-size: 12px;
}
</style>
