<template>
  <div>
    <van-field
        v-bind="$attrs"
        v-model="payTimeSelect"
        is-link
        readonly
        :label="label"
        name="calendar"
        :placeholder="`选择${placeholder}时间${placeholder}区间`"
        @click="showCalendar = true"
    />
    <van-calendar ref="calendarRef" v-model:show="showCalendar" type="range" :show-confirm="false" :min-date="new Date(2020, 0, 1)" allow-same-day :default-date="calendarDate" @confirm="onConfirm" />
  </div>
</template>

<script>
import {Calendar, Field} from "vant";

export default {
  name: "TimeRangeSelect",
  components: {
    [Calendar.name]: Calendar,
    [Field.name]: Field,
  },
}
</script>
<script setup>
import {ref, defineProps, defineEmits, defineExpose, useAttrs, watch} from "vue";
import {date} from "@/utils/function";

const emits = defineEmits(["update:startTime","update:endTime","dochange"]);

const props = defineProps({
  defaultDate: {
    type: Array,
    default: () => {
      return []
    }
  },
  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
})

const calendarRef = ref('');
const payTimeSelect = ref('');
const showCalendar = ref(false);
const calendarDate = ref([]);
const attr = useAttrs()
watch(() => props.defaultDate, (newValue, oldVal) => {
  if(newValue.length > 0){
    payTimeSelect.value = `${date(newValue[0], 'Y-m-d')} 至 ${date(newValue[1], 'Y-m-d')}`
    calendarDate.value = [(new Date(newValue[0] * 1000)),(new Date(newValue[1] * 1000))]
    if(Object.prototype.hasOwnProperty.call(attr, "startTime")){
      emits("update:startTime",newValue[0]);
    }
    if(Object.prototype.hasOwnProperty.call(attr, "endTime")){
      emits("update:endTime",newValue[1]);
    }
  }
}, { immediate: true, deep: true })
const offsetTimestamp = (date) => {
  return Date.parse(date.toString()) / 1000;
}

const onConfirm = (values) => {
  showCalendar.value = false;
  const [start, end] = values;
  payTimeSelect.value = `${date(start, 'Y-m-d')} 至 ${date(end, 'Y-m-d')}`
  if(Object.prototype.hasOwnProperty.call(attr, "startTime")){
    emits("update:startTime",offsetTimestamp(start));
  }
  if(Object.prototype.hasOwnProperty.call(attr, "endTime")){
    emits("update:endTime",offsetTimestamp(end));
  }
  emits("dochange",values);
};
const handleReset = () => {
  calendarRef.value.reset()
  payTimeSelect.value = ''
  if(Object.prototype.hasOwnProperty.call(attr, "startTime")){
    emits("update:startTime",'');
  }
  if(Object.prototype.hasOwnProperty.call(attr, "endTime")){
    emits("update:endTime",'');
  }
  emits("dochange",'');
}
defineExpose({handleReset})
</script>

<style scoped>
</style>
