// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2024/4/29
// +-------------------------------------------------------------
// |Time: 16:10
// +-------------------------------------------------------------
import request from "@/utils/request";
//申请返利
export function rebateAdd(data){
    return request({
        method: 'post',
        url: '/user-active-rebate/add',
        data,
    })
}

export function rebateDetails(id){
    return request({
        method: 'post',
        url: '/user-active-rebate/details',
        data:{id},
    })
}
// 获取区服信息
export function getLastRoleInfo(small_id){
    return request({
        method: 'post',
        url: '/user-active-rebate/get-last-spend-record',
        data:{small_id},
    })
}
// 获取充值金额
export function getRechargeAmount(data){
    return request({
        method: 'post',
        url: '/user-active-rebate/get-small-recharge-amount',
        data,
    })
}

export function rebateList(data){
    return request({
        method: 'post',
        url: '/user-active-rebate/list',
        data,
    })
}
